import React from 'react';
import './footer.css';
import fb from '../../assets/fb.png';
import linkedin from '../../assets/linkedin.png';
import insta from '../../assets/insta.png';
import logo from '../../assets/brand-removebg.png'
import { Link } from 'react-router-dom';

// import { SocialIcon } from 'react-social-icons/component'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faInstagram, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {fa} from '@fortawesome/free-brands-svg-icons';

const today = new Date();
const year = today.getFullYear();

const handleLinkClick = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

const Footer = () => {
      return (
<div class="footer">
  <div class="container">
    {/* <div class="newsletter">
      <h2>Subscribe Our Newsletter</h2>
      <div class="form">
        <input class="form-control" placeholder="Email here"/>
        <button class="btn">Submit</button>
      </div>
    </div> */}
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="calligo-footerabout">
          <h3>About Us</h3>
          <p>
          CALLIGO IT, LLC is Information Technology (IT) and Management Consulting Business composed of certified IT and Management practitioners that specializes in architecture, engineering, implementation, integration and support services across many IT and Management disciplines, with a focus on Cloud Computing Services, Data Center Migrations, Robotics Process Automations, Cyber and Information Security, Infrastructure Management and Operations, Systems and Application Development and Management Consulting services.</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="calligo-footerlink">
              <h3>Service Area</h3>
              <Link to='/applicationdevelopmentautomation' className='menu-links' onClick={handleLinkClick}> Application Development and Automation</Link>
        <Link to='/cloudanddatacentermigration' className='menu-links' onClick={handleLinkClick}> cloud and Datacenter Migration (AWS/AZURE/GCP)</Link>
        <Link to='/cloudanddatacentersecurity' className='menu-links' onClick={handleLinkClick}> cloud and Datacenter Security</Link>
        <Link to='/datacentervirtualization' className='menu-links' onClick={handleLinkClick}> Data Center Virtualization and Consolidation</Link>
        <Link to='/entreprisenetworking' className='menu-links' onClick={handleLinkClick}> Enterprise Networking and Architecture</Link>
        <Link to='/ITInfrastructuremanagement' className='menu-links' onClick={handleLinkClick}> IT Infrastructure Management</Link>
        <Link to='/ITManagedservices' className='menu-links' onClick={handleLinkClick}> IT Managed Service</Link>
        <Link to='/roboticprocessautomation' className='menu-links' onClick={handleLinkClick}> Robotic Process Automation (RPA)</Link>
        <Link to='/servernetworkdatabasesecurityadministration' className='menu-links' onClick={handleLinkClick}> Server/Network/Database/Security Administration</Link>

            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="calligo-footerlink">
              <h3>Useful Link</h3>
              <Link to='/' className='menu-links' onClick={handleLinkClick}>Home</Link>
              <Link to="/aboutus" className='menu-links' id="#aboutus" onClick={handleLinkClick}>About us</Link>
              <Link to='/services' className='menu-links' onClick={handleLinkClick}> Services</Link>
              {/* <a href="possibility">Private Equity</a> */}
              <a href="#contactus">Contact Us</a>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="calligo-footercontact">
              <h3>Get In Touch</h3>
              <p>2940 Annapolis Way, Cumming,<br/>GA - 30041</p>
              <br/>
              <p>+1 630 450 7220</p>
              <p>customerconnect@calligoitsolutions.com</p>
              <div class="calligo-footersocial">
                {/* <a href=""><i class="fab fa-twitter"></i></a> */}
                <a href="https://www.facebook.com/calligoit" target="_blank"><FontAwesomeIcon icon={faFacebook}/></a>
                {/* <a href=""><i class="fab fa-youtube"></i></a> */}
                <a href="https://www.instagram.com/calligoitsolutions" target="_blank"><FontAwesomeIcon icon={faInstagram}/></a>
                <a href="https://wa.me/16304507220" target='_blank'><FontAwesomeIcon icon={faWhatsapp}/></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container calligo-footermenu">
    <div class="f-menu">
      <a href="">Terms of use</a>
      <a href="">Privacy policy</a>
      <a href="">Cookies</a>
      <a href="">Help</a>
      <a href="">FQAs</a>
    </div>
  </div>
  <div class="container copyright">
    <div class="row">
      <div class="col-md-6">
        <p>&copy; <a href="/">Calligo IT Solutions</a>, All Right Reserved.</p>
      </div>
      <div class="col-md-6">
        {/* <p>Designed By <a href="">HTML Codex</a></p> */}
      </div>
    </div>
  </div>
</div>
      )
 
}

export default Footer